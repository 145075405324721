const awsmobile = {
  Auth: {
    // フェデレーションアイデンティティのID
    identityPoolId: 'ap-northeast-1:879d917e-d76c-433c-b3bc-47a7b6f02a46',
    // リージョン
    region: 'ap-northeast-1',
    // ユーザープールのID
    userPoolId: 'ap-northeast-1_VkXxTP8nt',
    // ユーザープールのウェブクライアントID
    userPoolWebClientId: '2m8ot6ahuck6vpu8sr9idtk5s0',
    // mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'order-real-ui-api',
        // eslint-disable-next-line
        endpoint: 'https://api.real.order.genky-aws-dev.com/b1/graphql',
        region: 'ap-northeast-1',
      },
    ],
  },
}
export default awsmobile
