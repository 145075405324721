
























import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { ApolloError } from 'apollo-client'
import { Hub } from 'aws-amplify'
import Vue from 'vue'

import AccessErrorView from './components/templates/c/AccessErrorView.vue'

import apiClient from '@/utils/apiClient'
import Toast, { genNotifyMessage } from '@/utils/toast'
import { COGNITO_CODE_MAP } from '@/vue-i18n'

export default Vue.extend({
  components: { AccessErrorView },
  created() {
    /**
     * authSatte:
     *  signedin: ログイン
     *  resettingpassword: リセットパスワード／初回ログイン
     * authData: {
     *  Session
     *  attributes
     *  authenticationFlowType
     *  client
     *  keyPrefix
     *  pool
     *  preferredMFA
     *  signInUserSession: CognitoUserSession
     *  storage: Storage
     *  userDataKey
     *  username: "user01"
     * }
     */
    this.unsubscribeAuth = onAuthUIStateChange(
      async (authState, authData: any) => {
        console.log(authState)
        console.log(authData)
        if (authState !== 'signedin') {
          return
        }
        this.$store.commit('auth/signin', Object.assign({}, authData))
        if (this.$store.state.auth.authorization) {
          try {
            await apiClient.getUserSysInfo(this)
          } catch (e: unknown) {
            Toast.error(this, {
              message: genNotifyMessage('e.get', 'user-info', e as ApolloError),
            })
          }
        }
      }
    )
  },
  computed: {
    hasRightData() {
      console.log('hasRightData update')
      return this.$store.state.app.userInfo !== null
    },
    canAccessRight() {
      return () => {
        this.$nextTick()
        return this.$store.getters['app/canAccessRight'](location.pathname)
      }
    },
  },
  mounted() {
    Hub.listen('auth', (res) => {
      console.log(res)
      const { event } = res.payload
      if (event === 'tokenRefresh') return

      const { code } = res.payload.data
      let message = res.payload.data.message
      if (Object.keys(COGNITO_CODE_MAP).includes(event)) {
        // @ts-ignore if can be checked
        if (Object.keys(COGNITO_CODE_MAP[event]).includes(code)) {
          // @ts-ignore if can be checked
          message = this.$i18n.t(COGNITO_CODE_MAP[event][code])
        }
      }
      Toast.error(this, { message })
    })
  },
  beforeDestroy() {
    this.unsubscribeAuth()
  },
})
